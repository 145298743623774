import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vant from 'vant'
// 2. 引入组件样式
import 'vant/lib/index.css';
//状态管理
import store from './store'

import SocketSingleton from './utils/SocketSingleton.js';
import { AppConfig } from './appConfig.js';

// 全局注册
const app = createApp(App);
app.use(router);
app.use(vant);
app.use(store);

// 全局socket属性
app.config.globalProperties.$socket = SocketSingleton.getInstance();
// 设置全局配置
app.config.globalProperties.$appConfig = AppConfig;

app.mount('#app')