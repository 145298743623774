import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/pyq/paying',
    name: '/pyq/paying',
    component: () => import('@/components/pyq/paying.vue')
  },
  {
    path: '/pyq/index',
    name: '/pyq/index',
    component: () => import('@/components/pyq/index.vue')
  },
  {
    path: '/pyq/tutorials',
    name: '/pyq/tutorials',
    component: () => import('@/components/pyq/tutorials.vue')
  },
  {
    path: '/pyq/publish',
    name: '/pyq/publish',
    component: () => import('@/components/pyq/publish.vue')
  },
  {
    path: '/pyq/details/:id',
    name: '/pyq/details',
    component: () => import('@/components/pyq/details.vue')
  },
  {
    path: '/pyq/visitors',
    name: '/pyq/visitors',
    component: () => import('@/components/pyq/visitors.vue')
  },
  {
    path: '/pyq/visitors_by_pyq',
    name: '/pyq/visitors_by_pyq',
    component: () => import('@/components/pyq/visitors_by_pyq.vue')
  },
  {
    path: '/pyq/home',
    name: '/pyq/home',
    component: () => import('@/components/pyq/home.vue')
  },
  {
    path: '/pyq/member_pay',
    name: '/pyq/member_pay',
    component: () => import('@/components/pyq/member_pay.vue')
  },
  {
    path: '/chat/chat',
    name: '/chat/chat',
    component: () => import('@/components/chat/chat.vue')
  },
  {
    path: '/',
    name: '/',
    component: () => import('@/components/pyq/paying.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router