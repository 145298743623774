import io from 'socket.io-client';
import { AppConfig } from '@/appConfig';
class SocketSingleton {
  constructor() {
    if (!SocketSingleton.instance) {
      // 配置参数
      // this.url = 'wss://ws.toutou.online'; // 服务器地址
      this.url = 'wss://' + AppConfig.baseUrl; // 服务器地址
      this.options = { reconnection: false }; // Socket.IO 连接选项，禁用自动重连
      this.maxReconnectAttempts = 5; // 最大重试次数
      this.reconnectInterval = 2000; // 重试间隔时间（毫秒）

      this.socket = null;
      this.reconnectAttempts = 0;
      this.isReconnecting = false; // 添加一个标志，表示当前是否正在尝试重连

      // 初始化 Socket 连接
      this.initializeSocket();

      // 保存单例实例
      SocketSingleton.instance = this;
    }

    return SocketSingleton.instance;
  }

  // 初始化 Socket 连接
  initializeSocket() {
    this.socket = io(this.url, this.options);

    // 连接成功事件
    this.socket.on('connect', () => {
      console.log('Connected to the server');
      this.reconnectAttempts = 0; // 重置重试次数
      this.startHeartbeat(); // 连接成功后启动心跳
      // 增加延迟发送消息的代码
      setTimeout(() => {
        this.sendMessage("1000", {});
      }, 500);
    });

    // 断开连接事件
    this.socket.on('disconnect', () => {
      console.log('Disconnected from the server');
      this.reconnect(); // 尝试重连
    });

    // 连接错误事件
    this.socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
      this.reconnect(); // 尝试重连
    });
  }

  // 尝试重连
  reconnect() {
    this.stopHeartbeat(); // 断开连接时停止心跳
    if (this.isReconnecting) { // 如果已经在尝试重连，那么就不再进行新的重连尝试
      return;
    }

    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.isReconnecting = true; // 开始尝试重连
      this.reconnectAttempts++;
      console.log(`Reconnect attempt ${this.reconnectAttempts}/${this.maxReconnectAttempts}`);
      if (this.socket) {
        this.socket.close(); // 关闭旧的Socket连接
      }
      setTimeout(() => {
        this.initializeSocket();
        this.isReconnecting = false; // 重连尝试结束
      }, this.reconnectInterval);
    } else {
      console.error('已达到最大重新连接尝试次数。无法重新连接到服务器');
    }
  }

  // 发送消息
  sendMessage(cmd, data) {
    if (this.socket && this.socket.connected) {
      // console.log(`Attempting to send message: cmd=${cmd}, data=${JSON.stringify(data)}`);
      this.socket.emit(cmd, data, (error) => {
        if (error) {
          console.error('消息发送失败:', error);
        }
      });
    } else {
      console.error('Socket 未连接，无法发送消息');
    }
  }
  //监听时间消息并回调
  on(eventName, callback) {
    if (this.socket) {
      this.socket.on(eventName, callback);
    } else {
      console.error('socket未连接，无法监听.');
    }
  }

  off(eventName, callback) {
    if (this.socket) {
      this.socket.off(eventName, callback);
      console.log("off:",eventName);
    } else {
      console.error('Socket not connected. Cannot remove event listener.');
    }
  }

  // 新增方法：启动心跳包发送
  startHeartbeat(interval = 5000) {
    if (this.socket && this.socket.connected) {
      console.log('Starting heartbeat...');
      this.heartbeatInterval = setInterval(() => {
        this.sendMessage('1000', { timestamp: new Date().toISOString() });
      }, interval);
    } else {
      console.warn('Socket not connected. Heartbeat not started.');
    }
  }

  // 新增方法：停止心跳包发送
  stopHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
      console.log('Heartbeat stopped.');
    }
  }


  /***
   * // 获取 Socket 单例实例
    const socket = SocketSingleton.getInstance();

    // 注册事件监听器
    socket.on('myEvent', (data) => {
      console.log('Received data:', data);
    });
   */

  // 获取单例实例
  static getInstance() {
    if (!SocketSingleton.instance) {
      SocketSingleton.instance = new SocketSingleton();
    }
    return SocketSingleton.instance;
  }
}

export default SocketSingleton;