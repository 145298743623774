<template>
  <router-view />
</template>

<script>
import { showNotify } from 'vant';
import SocketSingleton from './utils/SocketSingleton.js';
export default {
  components: {},
  data() {
    return {
      code: '',
      timerId: null
    }
  },


  created() {

    // // 初始化问题延时500毫秒
    // setTimeout(() => {
    //   let openid = this.$route.query.openid
    //   let access_token = this.$route.query.access_token
    //   if (openid && access_token) {
    //     this.$socket.sendMessage('1003', { "openid": openid, "access_token": access_token });
    //   } else {
    //     if (!sessionStorage.getItem("userinfo")) {
    //       // 危险通知
    //       showNotify({ type: 'danger', message: '登录失败' });
    //     }

    //   }
    // }, 1000);
  },
  // 监听事件
  mounted() {
    this.getSocket();
    this.$socket.on("1004", (data) => {
      sessionStorage.setItem("userinfo", JSON.stringify(data))
    });
  },
  beforeDestroy() {
    // 组件销毁前，移除所有 Socket 监听器，避免内存泄漏
    this.$socket.off('1004', () => { });
  },
  methods: {
    // 获取Socket，每隔1000毫秒（1秒）检查一次Socket是否连接
    getSocket(interval = 100) {
      let tryNum = 0;

      // 设置一个定时器，每隔100毫秒检查一次Socket是否连接
      this.timerId = setInterval(() => {
        // 从请求地址中获取参数发送事件
        let openid = this.$route.query.openid
        let access_token = this.$route.query.access_token

        let socket = SocketSingleton.getInstance()
        console.log(socket.socket.connected);
        // 如果Socket已连接
        if (socket.socket.connected) {
          // 请求参数包含登录所需信息
          if (openid != undefined && access_token != undefined) {
            this.$socket.sendMessage('1003', { "openid": openid, "access_token": access_token });
          } else {
            // 判断缓存是否存在用户信息
            if (!sessionStorage.getItem("userinfo")) {
              showNotify({ type: 'danger', message: '登录失败，请重新登录' });
              //TODO
              // 跳转到登录页面,携带window.local.href并回调
            }
          }
          tryNum = 0//重置重试次数
          // 清除定时器
          clearInterval(this.timerId);
        } else {
          tryNum++
          // 最大重试20次
          if (tryNum >= 20) {
            clearInterval(this.timerId);
          }
        }
      }, interval);
    },
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a.router-link-exact-active {
  color: #42b983;
}

body {
  height: 100%;
}
</style>